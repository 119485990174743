import React, { useEffect, useState } from "react";
import { Card, Nav, Tab } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useSearchParams } from "react-router-dom";
import LabelValue from "../../components/label-value";
import { InlineLink } from "../../components/Link/link";
import { withActiveSubscrption } from "../../contexts/auth.context";
import { useSelectedActContext } from "../../contexts/selected-act.context";
import { Selection } from "../../hooks/useMultipleSelection";
import SummaryQa from "../../models/SummaryQa.model";
import { fetchAnswer, summaryHref, useQuestions } from "../../services/summary-services";
import ResultsTable from "../SearchPage/ResultsTable/results-table";
import "../SearchPage/SearchResults/search-results.scss";

const SearchResultsLexebraGPT: React.FC<{
  hasAnswer: boolean;
  question: string;
  setAnswerGenerated: () => void;
}> = ({ hasAnswer, question, setAnswerGenerated }) => {
  const results = useQuestions();
  const [searchParams] = useSearchParams();
  const query = searchParams.get("q");
  const { setSelectedActId } = useSelectedActContext();
  const { formatMessage } = useIntl();

  // Initialize 'answer' as an empty string
  const [answer, setAnswer] = useState<string>("");

  const questionIds = results.data
    ?.flatMap((result) => result._embedded.items)
    .map((qa) => qa.id)
    .join(",");

  useEffect(() => {
    setAnswer("");
    if (questionIds && question) {
      fetchAnswer(question, questionIds.split(","), (chunk) => {
        if (chunk !== null) {
          setAnswer((prevAnswer) => prevAnswer + chunk);
        } else {
          setAnswerGenerated();
        }
      });
    }
  }, [questionIds, setAnswerGenerated, question]);

  if (!query) {
    return <div className="no-results"></div>;
  }

  if (results.totalCount === 0) {
    return <div className="no-results">Няма намерени резултати.</div>;
  }

  return (
    <>
      <Card className={`ms-3 mb-3 me-3 search-card`}>
        <Card.Body style={{ flexDirection: "column" }}>
          {!!question && (
            <p>
              <Card.Text className="fw-bold">Въпрос: </Card.Text>
              <Card.Text>{question}</Card.Text>
            </p>
          )}
          <Card.Text className="fw-bold">Отговор: </Card.Text>
          <Card.Text className={query && !hasAnswer ? "loading" : ""}>{answer}</Card.Text>
        </Card.Body>
      </Card>
      {hasAnswer && (
        <Tab.Container defaultActiveKey={"0"}>
          <Nav variant="pills" className="opinion-tabs">
            <Nav.Item>
              <Nav.Link eventKey="0">
                <FormattedMessage id="view-summary.labels.relatedJudgementActs.questions" />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="1">
                <FormattedMessage id="view-summary.labels.relatedJudgementActs.acts" />
              </Nav.Link>
            </Nav.Item>
          </Nav>

          <Tab.Content>
            <Tab.Pane eventKey="0">
              <ResultsTable
                results={results}
                header={(params) => <></>}
                itemToSelection={itemToSelection}
                allowSelection={false}
                row={(item) => (
                  <ResultsTable.ResultTableRow>
                    <InlineLink
                      to={summaryHref(`${item.summary.id}#${item.id}`)}
                      onClick={(e) => {
                        if (!e.ctrlKey && !e.metaKey) {
                          e.preventDefault();
                          setSelectedActId("summary:" + item.summary.id);
                        }
                      }}
                    >
                      {item.question}
                    </InlineLink>
                    <span className="text-muted ms-1">({item.similarityScore?.toFixed(2)})</span>
                    <div>{item.shortTitle}</div>
                    {item.caseType &&
                      item.caseType.code !== "CRIMINAL" &&
                      item.caseType.code !== "CRIMINAL_PRIVATE" && (
                        <LabelValue
                          label={formatMessage({
                            id: "view-judgement.labels.proceedingBy",
                          })}
                        >
                          {item.proceeding?.shortName}
                        </LabelValue>
                      )}

                    <ResultsTable.RowIndicators item={item} />
                    <ResultsTable.RowHighlights item={item} />
                  </ResultsTable.ResultTableRow>
                )}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="1">
              <div className="act-text">Coming soon</div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      )}
    </>
  );
};

const itemToSelection = (item: SummaryQa): Selection => ({
  _id: item.id,
  title: item.question,
  href: summaryHref(`${item.summary.id}#${item.id}`),
  hasSummary: false,
  hasAnnotations: false,
  hasEditorAnnotation: false,
  hasAiAnnotations: false,
});

export default withActiveSubscrption(SearchResultsLexebraGPT);
