// search-menu.js
import React from "react";
import { NavDropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router";
import { useDeviceSelectors } from "react-device-detect";
import { useSearchContext } from "../../contexts/search.context";
import { popperConfig } from "./header";

const SearchMenu = () => {
  const navigate = useNavigate();
  const [{ isMobileOnly }] = useDeviceSelectors(window.navigator.userAgent);
  const { lastSearchHref } = useSearchContext();

  if (isMobileOnly) {
    return (
      <NavDropdown
        title={
          <div className="link-body">
            <OverlayTrigger
              placement="bottom"
              popperConfig={popperConfig}
              overlay={(props) => (
                <Tooltip id="search-tooltip" {...props}>
                  Търсене
                </Tooltip>
              )}
            >
              <span>
                <FaSearch style={{ rotate: "90deg" }} />{" "}
              </span>
            </OverlayTrigger>
            <span className="nav-link-label">Търсене</span>
          </div>
        }
        id="nav-dropdown-search"
      >
        {/* Dropdown items */}
        <NavDropdown.Item onClick={() => navigate("/lexebragpt-search")}>
          Питай Лексебра
        </NavDropdown.Item>
        <NavDropdown.Item onClick={() => navigate("/search")}>
          Основно търсене
        </NavDropdown.Item>
      </NavDropdown>
    );
  }

  return (
    <NavDropdown
      title={
        <div className="link-body">
          <OverlayTrigger
            placement="bottom"
            popperConfig={popperConfig}
            overlay={(props) => (
              <Tooltip id="search-tooltip" {...props}>
                Търсене
              </Tooltip>
            )}
          >
            <span
              onClick={() => navigate(lastSearchHref || "/search")}
              style={{ cursor: "pointer" }}
            >
              <FaSearch style={{ rotate: "90deg" }} />{" "}
            </span>
          </OverlayTrigger>
          <span
            className="nav-link-label"
            onClick={() => navigate(lastSearchHref || "/search")}
            style={{ cursor: "pointer" }}
          >
            Търсене
          </span>
        </div>
      }
      id="nav-dropdown-search"
      onClick={(e) => {
        e.preventDefault();
      }}
    >
      {/* Dropdown items */}
      <NavDropdown.Item onClick={() => navigate("/lexebragpt-search")}>
        Питай Лексебра
      </NavDropdown.Item>
      <NavDropdown.Item onClick={() => navigate("/search")}>
        Основно търсене
      </NavDropdown.Item>
    </NavDropdown>
  );
};

export default SearchMenu;
